<template>
  <div id="myWallet">
    <div class="top-wallet">
      <div class="info-box">
        <div class="total-box">
          <p>账户总览</p>
          <div>
            <!-- <span>冻结金额</span>
            <i>￥{{infoData.freeze_money}}</i>-->
          </div>
        </div>

        <div class="use-box">
          <div>
            <p>可结算金额</p>
            <span>{{infoData.available_amount == '暂未开通'? infoData.available_amount :'￥' + infoData.available_amount}}</span>
          </div>
          <div>
            <p>冻结金额</p>
            <span>{{infoData.pending_amount == '暂未开通'? infoData.pending_amount :'￥' + infoData.pending_amount}}</span>
          </div>
        </div>

        <div class="cash-btn" @click="$router.push({path: '/cashOut'})">立即提现</div>
      </div>

      <!-- <div @click="$router.push({path: '/myCard'})">
        <img class="bank-card" src="@images/bank-card.png" alt />
        <div class="card-text">我的银行卡</div>
      </div>-->
    </div>

    <van-pull-refresh class="detail-list-container" v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getInfo">
        <div class="detail-box" v-for="(item, index) in infoList" :key="index">
          <div class="today-box">
            <p>{{item.create_at}}</p>
            <p>{{item.create_at === '今天' ? '刷新' : ''}}</p>
          </div>
          <div class="detail-item" v-for="(items, indexs) in item.MerchRevenueList" :key="indexs">
            <div class="top">
              <p class="title">{{items.remarks}}</p>
              <p class="price">{{items.amount_type == 1 ? '+￥' : '-￥'}}{{items.operating_amount}}</p>
            </div>
            <div class="bottom">
              <p v-if="items.cost_type == 1">商品销售</p>
              <p v-if="items.cost_type == 2">缺货退款</p>
              <p v-if="items.cost_type == 3">申请退款</p>
              <p v-if="items.cost_type == 4">取消订单</p>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
  import {walletInfo} from "../api/requist";

  export default {
    components: {},
    props: [],
    data() {
      return {
        loading: false,
        finished: false,
        refreshing: false,
        pageCurrent: 1,
        pageSize: 5,
        infoData: {},
        infoList: []
      };
    },
    created() {
    },
    mounted() {
    },
    watch: {},
    computed: {},
    methods: {
      async getInfo() {
        if (this.refreshing) {
          this.pageCurrent = 1;
          this.infoList = [];
          this.refreshing = false;
        }
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("pageCurrent", this.pageCurrent);
        formData.append("pageSize", this.pageSize);
        let res = await walletInfo(formData);

        if (res.code === 200) {
          if (this.pageCurrent === 1) {
            this.infoData = res.data.Balance;
          }
          this.infoList.push(...res.data.RevenueList);
          if (res.data.RevenueList.length !== 0) {
            this.pageCurrent = ++this.pageCurrent;
          } else {
            this.finished = true;
          }
          this.loading = false;
        } else {
          this.finished = true;
        }
      },
      onRefresh() {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.getInfo();
      }
    }
  };
</script>
<style lang="scss">
  @import "../common/css/theme.scss";

  #myWallet {
    min-height: 100vh;

    .top-wallet {
      padding: 3.2 * 3.75px 4.8 * 3.75px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);

      .info-box {
        margin: 0 auto;
        padding: 3.2 * 3.75px 4.8 * 3.75px 0;
        box-sizing: border-box;
        height: 44 * 3.75px;
        background: linear-gradient(180deg, $wallte-color 0%, $theme-color 100%);
        border-radius: 1 * 3.75px;

        .total-box {
          margin-bottom: 4.8 * 3.75px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            color: rgba(255, 255, 255, 1);
            font-size: 4.8 * 3.75px;
          }

          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          span {
            margin-right: 1.6 * 3.75px;
            font-size: 3.2 * 3.75px;
            color: rgba(255, 255, 255, 0.8);
          }

          i {
            font-style: normal;
            color: rgba(255, 255, 255, 1);
            font-size: 3.2 * 3.75px;
          }
        }

        .use-box {
          margin-bottom: 1.6 * 3.75px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > div:last-child {
            margin-right: 121px;
          }

          p {
            margin-bottom: 1.6 * 3.75px;
            text-align: left;
            font-size: 3.2 * 3.75px;
            color: rgba(255, 255, 255, 0.8);
          }

          span {
            display: block;
            text-align: left;
            font-size: 4.27 * 3.75px;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
          }
        }

        .cash-btn {
          margin-top: 6.4 * 3.75px;
          height: 7.73 * 3.75px;
          background: rgba(245, 247, 250, 1);
          border-radius: 4 * 3.75px;
          font-size: 3.2 * 3.75px;
          text-align: center;
          line-height: 7.73 * 3.75px;
          color: rgba(48, 49, 51, 1);
        }
      }

      .bank-card {
        display: block;
        height: 8.53 * 3.75px;
        width: 8.53 * 3.75px;
        margin: 4.8 * 3.75px 0 1.6 * 3.75px 6.93 * 3.75px;
      }

      .card-text {
        padding-left: 3.2 * 3.75px;
        box-sizing: border-box;
        text-align: left;
        color: rgba(96, 98, 102, 1);
        font-size: 3.2 * 3.75px;
      }
    }

    .today-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 18px;
      box-sizing: border-box;
      background: rgba(240, 242, 245, 1);

      p {
        color: rgba(48, 49, 51, 1);
        font-size: 12px;
        line-height: 12*1.4px;
      }
    }

    .detail-list-container {
      background-color: #f0f2f5;

      .detail-box {
        width: 100%;

        .detail-item {
          padding: 12px 18px;
          background: rgba(255, 255, 255, 1);
          border-bottom: 1px solid rgba(240, 242, 245, 1);

          &:last-child {
            border-bottom: none;
          }

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              max-width: 200px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            p {
              font-size: 14px;
              line-height: 14*1.4px;
              color: rgba(48, 49, 51, 1);
            }
          }

          .bottom {
            margin-top: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 12px;
              line-height: 12*1.4px;
              color: rgba(144, 147, 153, 1);
            }
          }
        }
      }
    }
  }
</style>
